<!--
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-15 00:50:55
 * @FilePath: \zyt-mobile-frontend\src\views\cinemaAssistant\modules\index\publicService.vue
-->
<template>
  <div class="page">
    <div class="header">
      <span class="title">公共服务</span>
      <van-icon name="arrow" color="#fff" />
    </div>
    
    <div class="service-grid">
      <div 
        class="service-item"
        @click="goPath('/cinemaAssistant/policyCompilation', { id: cinemaId })"
      >
        <div class="icon-wrapper blue">
          <van-icon name="label-o" size="24" />
        </div>
        <div class="service-name">政策汇编</div>
        <div class="service-desc">最新政策动态</div>
      </div>

      <div 
        class="service-item"
        @click="goPath('/cinemaAssistant/filmArrangement')"
      >
        <div class="icon-wrapper green">
          <van-icon name="bar-chart-o" size="24" />
        </div>
        <div class="service-name">排片参考</div>
        <div class="service-desc">智能排片建议</div>
      </div>

      <div class="service-item">
        <div class="icon-wrapper purple">
          <van-icon name="gift-o" size="24" />
        </div>
        <div class="service-name">更多服务</div>
        <div class="service-desc">敬请期待</div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.page {
  width: 94%;
  margin: 16px auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.header {
  padding: 16px 20px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 20px;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  
  &:active {
    background: #f5f5f5;
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    
    &.blue {
      background: rgba(25, 137, 250, 0.1);
      color: #1989fa;
    }
    
    &.green {
      background: rgba(52, 204, 95, 0.1);
      color: #34cc5f;
    }
    
    &.purple {
      background: rgba(157, 80, 245, 0.1);
      color: #9d50f5;
    }
  }

  .service-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 4px 0;
  }

  .service-desc {
    font-size: 12px;
    color: #999;
  }
}
</style>

<script>
export default {
  name: 'PublicService',
  props: {
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    goPath(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
  },
}
</script>
