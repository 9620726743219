<template>
  <div class="page">
    <div class="header">
      <span class="title">预警信息</span>
      <van-icon name="arrow" color="#fff" />
    </div>
    
    <div class="warning-grid">
      <div
        class="warning-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 0, id: cinemaId })"
      >
        <div class="icon-wrapper red">
          <van-icon name="warning-o" size="24" />
        </div>
        <div class="warning-name">经营预警</div>
        <div class="warning-count">
          <span class="unread">{{ datas.JyNotReadCount }}</span>
          <span class="divider">/</span>
          <span class="total">{{ datas.JyWarningCount }}</span>
        </div>
      </div>

      <div
        class="warning-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 1, id: cinemaId })"
      >
        <div class="icon-wrapper orange">
          <van-icon name="fire-o" size="24" />
        </div>
        <div class="warning-name">消防预警</div>
        <div class="warning-count">
          <span class="unread">{{ datas.XfNotReadCount }}</span>
          <span class="divider">/</span>
          <span class="total">{{ datas.XfWarningCount }}</span>
        </div>
      </div>

      <div
        class="warning-item"
        @click="goPath('/filmAssistant/warningMsg', { t: 2, id: cinemaId })"
      >
        <div class="icon-wrapper purple">
          <van-icon name="shield-o" size="24" />
        </div>
        <div class="warning-name">疫情预警</div>
        <div class="warning-count">
          <span class="unread">{{ datas.YqNotReadCount }}</span>
          <span class="divider">/</span>
          <span class="total">{{ datas.YqWarningCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.page {
  width: 94%;
  margin: 16px auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.header {
  padding: 16px 20px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
}

.warning-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 20px;
}

.warning-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px;
  border-radius: 8px;
  transition: all 0.3s ease;
  
  &:active {
    background: #f5f5f5;
  }

  .icon-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    
    &.red {
      background: rgba(245, 34, 45, 0.1);
      color: #f5222d;
    }
    
    &.orange {
      background: rgba(250, 140, 22, 0.1);
      color: #fa8c16;
    }
    
    &.purple {
      background: rgba(114, 46, 209, 0.1);
      color: #722ed1;
    }
  }

  .warning-name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 4px 0;
  }

  .warning-count {
    font-size: 16px;
    
    .unread {
      color: #f5222d;
      font-weight: 600;
    }
    
    .divider {
      color: #d9d9d9;
      margin: 0 4px;
      font-size: 20px;
    }
    
    .total {
      color: #999;
    }
  }
}
</style>

<script>
export default {
  name: 'WarnInformation',
  props: {
    datas: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    goPath(path, query) {
      this.$router.push({ path, query })
    },
  },
}
</script>
