<template>
  <div class="page">
    <div class="header">
      <span class="title">影院画像</span>
      <van-icon name="arrow" color="#fff" />
    </div>

    <div class="portrait-grid">
      <div class="portrait-item" @click="toPath('/cinemaPortrait/yearBoxOffice', { id: cinemaId })">
        <div class="item-title">本年度票房</div>
        <div class="item-value">
          <span class="main-value">{{ datas.BoxOfficeNoService || '0' }}万</span>
          <span :class="['change-value', datas.BoxOfficeCompare < 0 ? 'decrease' : 'increase']">
            {{ datas.BoxOfficeCompare || '0' }}万
          </span>
        </div>
      </div>

      <div class="portrait-item" @click="toPath('/cinemaPortrait/CinemaRank', { id: cinemaId })">
        <div class="item-title">所在城市票房排名</div>
        <div class="item-value">
          <span class="main-value">{{ datas.CityRank || '0' }}名</span>
          <span :class="['change-value', datas.CityRankAdd < 0 ? 'decrease' : 'increase']">
            {{ datas.CityRankAdd || '0' }}名
          </span>
        </div>
      </div>

      <div class="portrait-item" @click="toPath('/cinemaPortrait/oneScreenProduct', { id: cinemaId })">
        <div class="item-title">单银幕产出</div>
        <div class="item-value">
          <span class="main-value">{{ (datas.AvgScreenValue / 10000).toFixed(2) || '0' }}万</span>
          <span :class="['change-value', datas.AvgScreenValueAdd < 0 ? 'decrease' : 'increase']">
            {{ (datas.AvgScreenValueAdd / 10000).toFixed(2) || '0' }}万
          </span>
        </div>
      </div>

      <div class="portrait-item" @click="toPath('/cinemaPortrait/averageFare', { id: cinemaId })">
        <div class="item-title">平均票价</div>
        <div class="item-value">
          <span class="main-value">{{ datas.AvgPrice || '0' }}元</span>
        </div>
      </div>

      <div class="portrait-item" @click="toPath('/cinemaPortrait/averagePerson', { id: cinemaId })">
        <div class="item-title">场均人次</div>
        <div class="item-value">
          <span class="main-value">{{ datas.AvgSaleBySession || '0' }}人</span>
          <span class="sub-value">{{ datas.CityAvgSaleBySession }}人</span>
        </div>
      </div>

      <div class="portrait-item" @click="toPath('/cinemaPortrait/fireSafety', { id: cinemaId })">
        <div class="item-title">消防安全等级</div>
        <div class="item-value">
          <span :class="['level-badge', 'level-' + datas.XfCinemaDj]">
            {{ datas.XfCinemaDj || '/' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.page {
  width: 94%;
  margin: 16px auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.header {
  padding: 16px 20px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
  }
}

.portrait-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 16px;
}

.portrait-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease;

  &:active {
    background: #f0f0f0;
  }

  .item-title {
    font-size: 13px;
    color: #666;
    margin-bottom: 8px;
  }

  .item-value {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 8px;

    .main-value {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .change-value {
      font-size: 13px;
      
      &.increase {
        color: #52c41a;
        &::before {
          content: "↑";
        }
      }
      
      &.decrease {
        color: #f5222d;
        &::before {
          content: "↓";
        }
      }
    }

    .sub-value {
      font-size: 13px;
      color: #999;
    }
  }

  .level-badge {
    padding: 4px 12px;
    border-radius: 4px;
    font-weight: 500;
    
    &.level-A {
      background: rgba(24, 144, 255, 0.1);
      color: #1890ff;
    }
    
    &.level-B {
      background: rgba(82, 196, 26, 0.1);
      color: #52c41a;
    }
    
    &.level-C {
      background: rgba(250, 173, 20, 0.1);
      color: #faad14;
    }
    
    &.level-D {
      background: rgba(245, 34, 45, 0.1);
      color: #f5222d;
    }
    
    &.level-E {
      background: rgba(0, 0, 0, 0.1);
      color: #000;
    }
  }
}
</style>

<script>
export default {
  props: {
    datas: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    cinemaId: {
      type: String,
      required: true,
      default: '',
    },
  },
  methods: {
    toPath(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
  },
}
</script>
