<template>
  <div class="cinema-card">
    <div class="cinema-header">
      <div class="cinema-title">
        <span class="name">{{ base.name }}</span>
      </div>
      <div class="actions">
        <van-button
          type="primary"
          size="small"
          round
          icon="search"
          @click="onchange"
          class="switch-btn"
        >切换影院</van-button>
        <div class="detail-link" @click="toPath('/filmAssistant/info', { ...base })">
          <van-icon name="notes-o" />
          <span>详情</span>
        </div>
      </div>
    </div>

    <div class="cinema-info">
      <div class="info-item">
        <div class="info-label">院线</div>
        <div class="info-value chain-name">{{ base.cinemaLine }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">法人代表</div>
        <div class="info-value">{{ base.user || '/' }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">影厅数</div>
        <div class="info-value">{{ base.numberOfCinemas }}</div>
      </div>
      <div class="info-item">
        <div class="info-label">座位数</div>
        <div class="info-value">{{ base.numberOfSeats }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CinemaBase',
  props: {
    base: {
      type: Object,
      default() {
        return {
          name: '',
          code: '',
          cinemaLine: '',
          user: '',
          numberOfCinemas: 0,
          numberOfSeats: 0,
        }
      },
    },
  },
  methods: {
    toPath(path, query) {
      this.$router.push({ path, query })
    },
    onchange() {
      this.$emit('change')
    },
  },
}
</script>

<style scoped lang="less">
.cinema-card {
  width: 94%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 12px;
  background: linear-gradient(135deg, #4facfe 0%, #00f2fe 100%);
  color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.cinema-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  .cinema-title {
    flex: 1;
    .name {
      font-size: 20px;
      font-weight: 600;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 12px;

    .switch-btn {
      background: rgba(255, 255, 255, 0.9);
      border: none;
      color: #4facfe;
      font-weight: 500;
      height: 32px;
      padding: 0 16px;
      
      .van-icon {
        color: #4facfe;
      }
      
      &:active {
        background: rgba(255, 255, 255, 0.7);
      }
    }

    .detail-link {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 6px 12px;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.15);
      transition: all 0.3s ease;
      font-size: 14px;
      
      .van-icon {
        font-size: 16px;
      }
      
      &:active {
        background: rgba(255, 255, 255, 0.25);
      }
    }
  }
}

.cinema-info {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  .info-item {
    text-align: center;
    position: relative;
    min-width: 0;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      height: 70%;
      width: 1px;
      background: rgba(255, 255, 255, 0.2);
    }

    .info-label {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 8px;
    }

    .info-value {
      font-size: 15px;
      font-weight: 500;
      line-height: 1.2;
      
      &.chain-name {
        word-break: break-all;
        white-space: normal;
        padding: 0 4px;
      }
    }
  }
}
</style>
